import { createStore, applyMiddleware, compose } from 'redux';
import thunks from 'redux-thunk';
import rootReducer from './reducers';

function configureStore() {
  // @ts-ignore
  const composeEnhancers = (process.env.NODE_ENV === 'development' && (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose)) || compose; // tslint:disable:max-line-length

  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunks),
    ),
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers').default);
    });
  }

  return store;
}

// Storage.
export default configureStore();
