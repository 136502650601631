import { transform, isEqual, isObject } from 'lodash';
import { IAttribute, AttributeType } from '@gsd/entity';
import Textbox from 'components/shared/Textbox';
import Checkbox from 'components/shared/Checkbox';
import Select from 'components/shared/Select';
import { PRODUCT_ROUTE_URL } from 'pages/Product/config';
import i18n from 'locales/i18n';
import { ValidationErrorType } from 'interfaces/Response/IError';

/**
 * Date format iso.
 */
export const DATE_FORMAT_ISO: string = 'yyyy-MM-dd';

/**
 * Date format.
 *
 */
export const DATE_FORMAT: string = 'dd MMM yyyy';

export const AUTH_TOKEN_KEY: string = 'AUTH_TOKEN';
export const REFRESH_TOKEN_KEY: string = 'REFRESH_TOKEN';
export const LANG_KEY: string = 'i18nextLng';
export const DEV_MODE_KEY: string = 'DEV_MODE';
export const APP_SIGN_IN_PATH: string = '/signin';
export const APP_INTERIM_PATH: string = PRODUCT_ROUTE_URL;

export const BYTES_PER_MBYTE = 1048576;

/**
 * Function for building REDUX store action's type
 * @param prefix string with action prefix like REQUESTING|SUCCESS|FAILURE
 * @param baseType string with base action type like GET_PROFILE etc
 */
export function buildStoreType(prefix: string, baseType: string): string {
  return `${prefix}/${baseType}`;
}

export function getConfig(): any {
  return (window as any).application || {};
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object       Object compared
 * @param  {Object} base         Object to compare with
 * @param  {Number} nestingLevel Number of levels how deep should be comparison
 * @return {Object}              Return a new object which represents the diff
 */
export function getDiff(object: any, base: any): any {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? getDiff(value, base[key])
          : value;
    }
  });
}

export function mapEntityFieldToInputComponent(field: IAttribute) {
  switch (field.type) {
    case AttributeType.Text:
      return Textbox;
    case AttributeType.Flag:
      return Checkbox;
    case AttributeType.Number:
      return Textbox;
    case AttributeType.Single:
      return Select;
    case AttributeType.Multi:
      return Select;
    default:
      return Textbox;
  }
}

export function getDevModeFeatures(): { [key: string]: boolean } {
  return {
    localization: !!getConfig().featureLocalization,
  };
}

export enum NotificationType {
  warning = 'warning',
  error = 'error',
}

export enum Brands {
  REEBOK = 'REEBOK',
  ADIDAS = 'ADIDAS',
}

export function buildQueryParams(params: any): string {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export function parseQueryParams(query: string): any {
  return query.split('&').reduce((acc: any, pair: string) => {
    const [key, value] = pair.split('=');
    if (key) {
      acc[key] = value;
    }

    return acc;
  },                             {});
}

export enum DocumentType {
  MENU = 'MENU',
  FOOTER = 'FOOTER',
  MEASUREMENT = 'MEASUREMENT',
  AGREEMENT = 'AGREEMENT',
}

export function setAuthData(authData: any) {
  window.localStorage.setItem(AUTH_TOKEN_KEY, authData.id_token);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, authData.refresh_token);
}

export function clearAuthData() {
  window.localStorage.removeItem(AUTH_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function getBackLink(url: string): string {
  let lastSlashIndex = url.lastIndexOf('/');
  if (lastSlashIndex === -1) {
    return url;
  }

  if (lastSlashIndex + 1 === url.length) {
    lastSlashIndex = url.lastIndexOf('/', lastSlashIndex - 1);
  }

  return url.slice(0, lastSlashIndex);
}

export function normalizeISODateString(date: string): string {
  if (!date) {
    return date;
  }

  const regEx =
    /([0-9]+)-([0-9]+)-([0-9]+)T([0-9]+):([0-9]+):([0-9]+)(\.([0-9]+)Z)?/;
  const parts = date.match(regEx);

  if (parts) {
    return `${parts[1]}-${parts[2]}-${parts[3]}T${parts[4] || '00'}:${
      parts[5] || '00'
    }:${parts[6] || '00'}.${parts[8] || '000'}Z`;
  }

  return date;
}

export function normalizeTimeString(time: string): string {
  if (!time) {
    return time;
  }

  const regEx = /.*(\d{2}):(\d{2}):(\d{2})/;
  const parts = time.match(regEx);

  if (parts) {
    return `${parts[1] || '00'}:${parts[2] || '00'}:${parts[3] || '00'}`;
  }

  return time;
}

export function formatFileSize(size: number, t: any) {
  const tempSize = size / 1024;

  if (tempSize < 1) {
    return `${parseFloat(size.toFixed(2))} ${t('uom.bytes')}`;
  }

  if (tempSize / 1024 < 1) {
    return `${parseFloat(tempSize.toFixed(2))} ${t('uom.kb')}`;
  }

  return `${parseFloat((tempSize / 1024).toFixed(2))} ${t('uom.mb')}`;
}

export enum availableLangs {
  english = 'en',
  germany = 'de',
}

export const langLabelsMapping: { [key: string]: string } = {
  en: 'english',
  de: 'deutsch',
};

export function switchLang(
  lang = 'en',
  successCallback?: () => void,
  errorCallback?: (err: string) => void,
) {
  if (getSavedLang() === lang) {
    return;
  }

  function changeCallback(err: string) {
    if (!err && successCallback) {
      window.localStorage.setItem(LANG_KEY, i18n.language);
      successCallback();
    } else if (err && errorCallback) {
      errorCallback(err);
    }
  }

  i18n.changeLanguage(lang, changeCallback);
}

export function getSavedLang() {
  return window.localStorage.getItem(LANG_KEY);
}

export function parseValidationErrors(errors: ValidationErrorType[]): {
  [key: string]: string;
} {
  return errors.reduce(
    (acc: { [key: string]: string }, val: ValidationErrorType) => {
      if (i18n.exists(`errors.${val.code}`)) {
        acc[val.attribute] = i18n.t(`errors.${val.code}`);
      } else {
        acc[val.attribute] = val.detail;
      }

      return acc;
    },
    {},
  );
}

export function parseErrorDetails(errors: ValidationErrorType[]): string[] {
  return errors.map((item: ValidationErrorType) => {
    if (i18n.exists(`errors.${item.code}`)) {
      return i18n.t(`errors.${item.code}`);
    }

    return item.detail;
  });
}
